.auth_main_section {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    margin: auto;
    margin-bottom: 60px;
    /* height: 50vh; */
}

.auth_main_sub_section {
    background-color: white;
    border: none;
    border-radius: 12px;
    padding: 5px 20px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.login_submit_btn {
    background-color: #b08e60 !important;
    color: white;
    font-size: 16px !important;
    font-weight: 700 !important;
    border-radius: 16px !important;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset !important;
    letter-spacing: 0.03em !important;
    line-height: 1 !important;
    opacity: 1 !important;
    outline: 0px !important;
    transition: background-color 0.2s, opacity 0.2s !important;
    height: 48px !important;
    padding: 0px 24px !important;
    justify-content: center !important;
}

.form-label {
    font-weight: 600;
    color: gray;
}

.wallet_section_register {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #e4e0e0;
    background-color: #fff;
    border-radius: 7px;
    padding: 8px 20px;
    width: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 0px inset; */
    /* padding-left: 32px;
    padding-right: 8px; */
}

.register_wallet_btn {
    background-color: #fff;
    color: #00bfff;
    border: 1px solid #00bfff;
    /* border: none; */
    border-radius: 5px;
    cursor: pointer !important;
    padding: 8px 20px;
    width: 100%;
    font-weight: 600;
}

@media (max-width: 757px) {
    .auth_main_section {
        max-width: 360px !important;
    }

}

/* .form-control {} */