:root {
    --primary-background-color: #fff;
    --primary-tab-background-color: #ededed;
    --primary-border-color: #fff;
    --seconday-border-color: #ededed;
    --primary-section-background-color: #e1e1e1;
    --secondary-section-background-color: #f3f3f3;
    --primary-text-color: #000;
    --secondary-text-color: #141414;
    --tertiary-text-color: #1b1b1b;
    --primary-black-border-color: #000;

}

body {
    background-color: var(--primary-background-color) !important;
}

.container {
    background-color: var(--primary-background-color);
}

.cake-staking-container {
    background-color: var(--primary-background-color);
    /* Light blue background */
}

.lock_phi_stake {

    padding: 5px;
    align-items: center;
    font-weight: 700 !important;
    border-radius: 16px !important;
    /* box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset !important; */
    transition: background-color 0.2s, opacity 0.2s !important;
    background: linear-gradient(229deg, #b08e60 -13.69%, #b08e60 91.33%);
    /* background-color: #1fc7d4 !important; */
    align-items: center !important;
    opacity: 1 !important;
    outline: 0px !important;
    color: black !important;
    margin-bottom: 18px;
    height: 48px;
}

.staking-title {
    font-size: 25px;
    color: var(--secondary-text-color);
    /* Purple color */
    font-weight: 900px;
    margin-bottom: 16px;
}

.staking-description {
    font-size: 16px;
    color: var(--tertiary-text-color);
    font-weight: 600;
    line-height: 120%;
    /* Subtle gray for text */
}

.get-cake-link {
    color: #b08e60;
    /* Green color */
    font-weight: bold;
    font-size: 1.1rem;
    text-decoration: none;
}

.new-staking-btn {
    background-color: #b08e60;
    color: black;
    font-weight: bold;
    border-radius: 50px;
    border-radius: 10px !important;
}

.new-staking-btn:hover {
    background-color: #5323b5;
    /* Darker shade on hover */
}

/* Styling for the image */
.staking-image {
    max-width: 100px;
    /* Adjust the size as needed */
    height: auto;
    margin-right: 20px;
}


/* LockCake.css */
.lock-cake-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: var(--primary-background-color);
}

#lock_phi {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.1;
    color: #280d5f;
}

.exact-layout-card {
    border: none;
    border-radius: 20px;
    background-color: #141414;
}

.label-title {
    color: var(--primary-text-color);
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    font-size: 16px;
}

.input-group-text {
    background-color: #414141;
    border: 1px solid #ced4da;
    color: #d5d5d5;
}

/* .form-control {
    background-color: black;
    color: white;
} */

.percentage-button,
.duration-button {
    /* width: 22%; */
    padding: 5px;
    font-size: 12px;
}

.percentage-button {
    background-color: #eff4f5 !important;
    color: #1fc7d4 !important;
    font-family: inherit;
    font-size: 16px !important;
    font-weight: 600 !important;
    transition: background-color 0.2s, opacity 0.2s !important;
    border: 0px;
    border-radius: 16px !important;
    cursor: pointer;
    padding: 2px 14px !important;
    /* width: 22%; */
    height: 32px !important;
}

.percentage-button:hover {
    background-color: #1fc7d4 !important;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
    color: #eff4f5 !important;
}

.duration-button {
    /* width: 18%; */
    background-color: #eeeaf4;
    color: #7a6eaa;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.2s, opacity 0.2s;
    border: 0px;
    border-radius: 16px !important;
    cursor: pointer;
    padding: 0 12px;
    height: 32px;
}

.duration-button:hover {
    background-color: #7a6eaa;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
    color: #eeeaf4;
}

.lock_overview_card {
    border-radius: 16px;
    background: linear-gradient(229deg, rgb(31, 199, 212) -13.69%, rgb(118, 69, 217) 91.33%);
    padding-top: 10px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
}

.total_apr,
.bribe_apr {
    font-weight: 700;
    background: linear-gradient(269deg, rgb(176, 142, 96) 7.46%, rgb(176, 142, 96) 99.29%) text;
    -webkit-text-fill-color: transparent;
}

.lock-overview {
    /* background: linear-gradient(90deg, #5b31ce 0%, #2575fc 100%); */
    color: #000;
    border-radius: 10px;
}

.overview-stats {
    padding: 5px 0;
    /* border-bottom: 1px solid #d0d0d0; */
}

.overview-stats:last-child {
    border-bottom: none;
}

.connect-wallet-btn {
    width: 100%;
    /* padding: 10px; */
    font-size: 16px !important;
    font-weight: 700 !important;
    border-radius: 16px !important;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset !important;
    transition: background-color 0.2s, opacity 0.2s !important;
    background-color: #b08e60 !important;

    align-items: center !important;

    opacity: 1 !important;
    outline: 0px !important;
    height: 48px;
    padding: 0px 24px;
    color: black !important;
}

.connect-wallet-btn1 {
    width: 100%;
    /* padding: 10px; */
    font-size: 16px !important;
    font-weight: 700 !important;
    border-radius: 16px !important;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset !important;
    transition: background-color 0.2s, opacity 0.2s !important;
    background-color: rgb(0, 172, 0) !important;

    align-items: center !important;

    opacity: 1 !important;
    outline: 0px !important;
    height: 48px;
    padding: 0px 24px;
    color: black !important;
}

.stake-connect-wallet-btn {
    width: 100%;
    /* padding: 10px; */
    font-size: 16px !important;
    font-weight: 700 !important;
    border-radius: 16px !important;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset !important;
    transition: background-color 0.2s, opacity 0.2s !important;
    /* background-color: #1fc7d4 !important; */
    background: linear-gradient(229deg, #b08e60 -13.69%, #b08e60 91.33%);
    align-items: center !important;

    opacity: 1 !important;
    outline: 0px !important;
    height: 48px;
    padding: 0px 24px;
    color: white !important;
}

.connect-wallet-btn:hover {
    border: 2px solid #1fc7d4;
    background-color: white;
    color: #1fc7d4;
}

@media (max-width: 768px) {
    .lock-cake-container {
        padding: 10px;
    }

    .percentage-button {
        width: 22%;
    }

    .duration-button {
        width: 18%;
    }

    .overview-stats {
        font-size: 14px;
    }

    .connect-wallet-btn {
        font-size: 14px;
        padding: 8px;
    }
}

.card {
    border-radius: 24px !important;
    background-color: var(--primary-section-background-color);
}

.card-body {
    padding: 20px;
}

.btn-info {
    background-color: #1fc7d4;
    border: none;
}

.card_bottons {
    width: 100%;
    font-size: 16px !important;
    font-weight: 700 !important;
    border-radius: 16px !important;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
    cursor: pointer !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s, opacity 0.2s !important;
    height: 48px;
    padding: 0px 24px !important;
    background-color: #b08e60 !important;
    color: black !important;
    margin-top: auto !important;
}

.card_bottons:hover {
    color: #1fc7d4;
    border: 2px solid #1fc7d4;
    background-color: white;

}

.view_details {
    /* width: 100%; */
    font-size: 16px !important;
    font-weight: 700 !important;
    border-radius: 16px !important;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
    cursor: pointer !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s, opacity 0.2s !important;
    height: 48px;
    padding: 0px 24px;
    background-color: #1fc7d4 !important;
    color: white !important;
    margin-top: auto;
    /* margin-right: 5px; */
}

.view_details:hover {
    color: #1fc7d4 !important;
    border: 2px solid #1fc7d4 !important;
    background-color: white !important;

}

.learn_more {
    font-size: 16px;
    font-weight: 700;
    border-radius: 16px !important;

    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s, opacity 0.2s;
    height: 48px;
    padding: 0px 24px;
    color: #1fc7d4 !important;
    border: 2px solid #1fc7d4 !important;
    background-color: white !important;
    margin-top: auto;
}

.learn_more:hover {
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset !important;
    background-color: #1fc7d4 !important;
    color: white !important;
}

.cards_botton_outline {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    border-radius: 16px !important;
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s, opacity 0.2s !important;
    height: 48px;
    padding: 0px 24px;
    color: #b08e60 !important;
    border: 2px solid #b08e60 !important;
    background-color: var(--primary-section-background-color) !important;
    margin-top: auto;
}

.cards_botton_outline:hover {
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
    background-color: #1fc7d4;
    color: white;
}