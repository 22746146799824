:root {
    --primary-background-color: #fff;
    --primary-tab-background-color: #ededed;
    --primary-border-color: #fff;
    --seconday-border-color: #ededed;
    --primary-section-background-color: #e1e1e1;
    --secondary-section-background-color: #f3f3f3;
    --primary-text-color: #000;
    --secondary-text-color: #141414;
    --tertiary-text-color: #1b1b1b;
    --primary-black-border-color: #000;

}

.wallet-section {
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: #b08e60;
    border-radius: 16px;
    padding: 7px 10px;
    box-shadow: rgb(135 133 133 / 10%) 0px -2px 0px inset;
    margin-right: 7px;
    /* padding-left: 32px;
    padding-right: 8px; */
}

.wallet-icon {
    margin-bottom: 5px;
}

.wallet-address {
    margin-left: 8px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.wallet-dropdown {
    position: absolute;
    top: 47px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
    width: 220px;
}

.wallet-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.wallet-dropdown ul li {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    color: #7a6eaa;
    font-weight: 400;
}

.wallet-dropdown ul li:hover {
    background-color: #494949;
    color: white;
    border: none;
    border-radius: 5px;
}

.wallet-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.wallet-popup {
    background-color: var(--secondary-section-background-color);
    /* padding: 20px; */
    border-radius: 10px;
    /* text-align: center; */
    max-width: 600px;
}

.wallet_popup_heading {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(139.73deg, #bfbfbf, #6b6b6b);
    border-bottom: 1px solid #e7e3eb;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
}

.wallet_popup_para {
    margin: 20px;
}

.wallet_address_show_wallet {
    align-items: center;
    background-color: var(--primary-background-color);
    border-radius: 16px;
    border: 0px;
    color: var(--primary-text-color);
    padding: 15px 20px;
    font-weight: 700;
    font-size: 16px;
    box-shadow: 1px 1px 3px gray;

}

.close-popup-btn {
    background-color: #b08e60;
    color: black;
    border: none;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
}

.close-popup-btn:hover {
    background-color: #003d99;
}

.wallet_popup_heading h3 {
    margin-bottom: 0;
}

@media (max-width: 600px) {
    .wallet-popup {
        max-width: 400px;
    }

    .wallet_address_show_wallet {
        font-size: 13px;
    }

}

@media (max-width: 400px) {
    .wallet-popup {
        max-width: 360px;
    }

    .wallet_address_show_wallet {
        padding: 15px 9px;
    }
}

.footer-container {
    background-color: var(--primary-section-background-color);
    border-top: 1px solid var(--primary-border-color);
    padding: 5px 7px;
}

.footer-container .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.footer-container .col i {
    font-size: 20px;
    /* color: #7a6eaa; */
    color: #b08e60;
    /* Purple color for icons */
}

.footer-container .col p {
    margin: 0;
    font-size: 10px;
    color: var(--primary-text-color);
}

/* .footer-container .col:nth-child(6) i {
    font-size: 25px;
    color: #39c0ed;
 
} */
.dropdown_menu_more {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
    width: 220px;
}

.dropdown_menu_more .dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    color: #7a6eaa;
    font-weight: 400;

}

.wallet-dropdown_menu_more a:hover {
    background-color: #494949;
    color: white;
    border: none;
    border-radius: 5px;
}

.transfer_token {
    background-color: #b08e60;
    padding: 5px 10px;
    color: white;
    text-decoration: none;
    margin-right: 20px;
    border-radius: 5px;
}