/* Custom styles for the deposit history cards */
.network_heading {
    background: linear-gradient(229deg, #a27e4e -13.69%, #a27e4e 91.33%);
    margin: 19px;
    color: white;
    padding: 8px;
    border: none;
    border-radius: 11px;
}

.deposit-card {
    border-radius: 10px;
}

.deposit-card p {
    margin-bottom: 3px;
}

.deposit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 120px; */
}

.deposit-icon img {
    width: 50px;
    height: auto;
}

.card_body_level {
    padding: 20px 20px 20px 20px;
}

@media (max-width: 768px) {
    .deposit-icon img {
        width: 50px;
    }
}

.main_container_height {
    margin-bottom: 100px !important;
}