:root {
  --primary-background-color: #fff;
  --primary-tab-background-color: #ededed;
  --primary-border-color: #fff;
  --seconday-border-color: #ededed;
  --primary-section-background-color: #e1e1e1;
  --secondary-section-background-color: #f3f3f3;
  --primary-text-color: #000;
  --secondary-text-color: #141414;
  --tertiary-text-color: #1b1b1b;
  --primary-black-border-color: #000;

}

.team-body-content {
  margin-bottom: 75px;
}

.sponcer_para {
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  color: var(--primary-text-color);
}

.team-transaction-box {
  background-color: var(--primary-section-background-color);
  padding: 8px 15px;
  border: 1px solid var(--secondary-section-background-color);
  border-radius: 10px;
  margin: 2px 16px;
  margin-bottom: 15px;
  text-decoration: none;
}

.team-transaction-name-2 {
  margin: 0;
  font-size: 12px;
  color: var(--primary-text-color);
  /* font-weight: 500; */
}

.report-date {
  color: var(--primary-text-color);
}

.report-amount {
  color: var(--primary-text-color);
}

.team-transaction-btn {
  background-color: #069cfa;
  border: 2px solid #069cfa;
  color: white;
  padding: 4px 12px;
  border-radius: 6px;
  text-align: center;
}

.transaction-btn2 {
  background-color: #52bfff;
  /* border: 2px solid #069cfa; */
  color: white;
  padding: 7px 10px;
  border-radius: 5px;
  text-align: center;
  margin: auto;
}