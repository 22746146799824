:root {
    --primary-background-color: #fff;
    --primary-tab-background-color: #ededed;
    --primary-border-color: #fff;
    --seconday-border-color: #ededed;
    --primary-section-background-color: #e1e1e1;
    --secondary-section-background-color: #f3f3f3;
    --primary-text-color: #000;
    --secondary-text-color: #141414;
    --tertiary-text-color: #1b1b1b;
    --primary-black-border-color: #000;

}

body {
    background-color: #edf6ff;
    font-family: 'Poppins', sans-serif;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-section-background-color);
    padding: 10px 20px;
    border-bottom: 1px solid var(--primary-border-color);
}

.left-section {
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 19px;
    width: 35px;
    margin-right: 10px;
}

.logo2 img {
    height: 45px;
    width: 50px;
    margin-right: 10px;
    border-radius: 10px;

}

.logo span {
    font-size: 18px;
    font-weight: bold;
    color: #ffb900;
}

.nav-links {
    display: flex;
    list-style: none;
    margin-left: 30px;
    margin-bottom: 0px;
}

.nav-links li {
    margin-right: 20px;
    font-size: 16px;
    cursor: pointer;
    color: #e1e1e1;
    position: relative;
    font-weight: 600;
}

.nav-links li:hover {
    color: #6236ff;
}

.nav-links li::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #6236ff;
    transition: width 0.3s;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.nav-links li:hover::after {
    width: 100%;
}

.right-section {
    display: flex;
    align-items: center;
}

.balance-section {
    font-size: 14px;
    margin-right: 20px;
    color: #fff;
}

.settings-icons {
    display: flex;
    justify-content: space-around;
}

.settings-icons i {
    font-size: 20px;
    margin-right: 15px;
    cursor: pointer;
    margin-top: 5px;
    color: #7a6eaa;
}

.network-status {
    background-color: var(--secondary-section-background-color);
    box-shadow: rgb(0 0 0 / 10%) 0px -2px 0px inset;
    color: var(--primary-text-color);
    font-size: 14px;
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 16px;
    /* height: 32px; */
    margin-right: 10px;

    /* height: 32px; */
    /* padding-left: 32px;
    padding-right: 8px; */
    margin-right: 10px;
}

.network-status2 {
    background-color: var(--secondary-section-background-color);
    box-shadow: rgb(0 0 0 / 10%) 0px -2px 0px inset;
    color: var(--primary-text-color);
    font-size: 14px;
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 16px;
    margin-top: 7px;
    width: 110px;
    height: 32px;
    margin-left: 10px
}

.wallet-btn {
    background-color: #b08e60;
    color: black;
    /* padding: 10px 20px; */
    border: none;
    border-radius: 20px;
    cursor: pointer;
    padding: 4px 20px;
    font-weight: 600;
}

.wallet-btn:hover {
    background-color: #ff9c00;
}

.Logoofmobile {
    display: none;
}

.mobilebutton {
    display: none;
}

/* Mobile View CSS */
@media (max-width: 600px) {
    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 10px;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1000;
        height: 55px;
    }

    .mobilebutton {
        display: flex;
    }

    /* 
    .desktopbutton {
        display: none;
    } */

    .Logoofdesktop {
        display: none;
    }

    .Logoofmobile {
        display: flex;
    }

    .left-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5px;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav-links {
        display: none;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        list-style-type: none;
        margin: 10px 0;
    }

    .nav-links li {
        margin: 5px 10px;
        font-size: 14px;
    }

    .right-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0px;
    }

    .balance-section {
        display: none;
    }

    .network-status {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        padding: 8px 12px;
        font-weight: 200;
    }

    .settings-icons {
        display: flex;
        gap: 2px;
    }

    .wallet-btn {
        padding: 8px 10px;
        font-size: 14px;
        border: none;
        background-color: #b08e60;
        color: black;
        border-radius: 25px;
        cursor: pointer;
    }

    /* 
    .swap-container {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #f0f9ff;
    } */

    /* .footer-container {
        display: flex;
        justify-content: space-around;
        padding: 20px;
        flex-wrap: wrap;
        flex-direction: column;
    } */

    .sab_nav_link {
        justify-content: start;
    }

    #footer_mobile_section {
        flex-direction: column;
    }
}

.toggle-switch {
    display: flex;
    background-color: #ffffff;
    border-radius: 25px;
    /* padding: 5px; */
    width: 300px;
    margin: 10px auto;
    justify-content: space-between;
}

.tab {
    flex: 1;

    background: var(--primary-tab-background-color);
    border: none;
    outline: none;
    padding: 7px 0;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    color: #6b5b95;
    transition: background-color 0.3s,
        color 0.3s;
    border-radius: 20px;
}

.tab.active {
    background-color: #6b5b95;
    color: #ffffff;
}


/* .swap-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f9ff;
} */

.swap-box {
    width: 320px;
    background-color: white;
    border-radius: 24px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.swap-header {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start;
    align-items: flex-start; */
    /* Align text to the left */
    margin-bottom: 20px;
}

.swap-header h3 {
    font-size: 20px;
    margin: 0;
    color: #280d5f;
    line-height: 1.1;
}


.swap-header p {
    font-size: 0.9rem;
    color: var(--secondary-text-color);
    margin: 5px 0 0 0;
    line-height: 1.5;
    font-weight: 400;
}


.swap-section {
    margin-bottom: 20px;
}

.token {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    /* background-color: #f7f9fc; */
    border-radius: 12px;
    margin-bottom: 10px;
}

.token-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.newSwap-token-logo {
    height: 50px;
    margin-right: 10px;
}

.token-icon {
    height: 30px;
    margin-right: 5px;
}

.newSwap-token-user-icon {
    height: 28px;
    margin-right: 10px;
}

.newSwapCenterHeading {
    text-align: center;
    margin-top: 30px;
}

.newSwapWalletAddress {
    font-size: 11px;
    margin-bottom: 0;
    background-color: #ffffff3b;
    padding: 2px 7px;
    border-radius: 16px;
    border: 1px solid #573f01;
}

.newSwapWalletIcon {
    height: 20px;
    margin-left: 10px;
}

.crypto-table-container {
    margin: 20px auto;
    max-width: 600px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.crypto-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    background-color: #ffffff;
}

.crypto-table thead {
    background-color: #f8f9fa;
    color: #333333;
}

.crypto-table th {
    text-align: left;
    padding: 10px;
    font-size: 14px;
    border-bottom: 2px solid #ddd;
}

.crypto-table tbody tr {
    border-bottom: 1px solid #ddd;
}

.crypto-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.crypto-table td {
    padding: 10px;
    font-size: 14px;
}

.crypto-symbol {
    display: flex;
    flex-direction: column;
}

.crypto-symbol span:first-child {
    font-weight: bold;
}

.market-cap {
    font-size: 12px;
    color: #777;
}


.token-select {
    font-size: 16px;
    background-color: transparent;
    border: none;
    color: #b08e60;
    cursor: pointer;
    -webkit-appearance: none;
    /* Safari and Chrome */
    -moz-appearance: none;
    /* Firefox */
    appearance: none;
    /* Modern browsers */
    background: transparent;
    /* Optional: makes the background of the dropdown transparent */
    padding-right: 6px;
    /* Optional: add some padding to the right */
    border: none;
    /* Optional: remove the border */
    background-image: none;
    /* Ensure no background image for the dropdown arrow */
}

.input-container {
    position: relative;
    display: inline-block;
    width: 95%;
    /* padding: 0 24px; */
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, .1);
}

input {
    width: 100%;
    /* Set the width of the input */
    height: 40px;
    /* Set the height of the input */
    padding: 10px;
    border-radius: 20px;
    /* background-color: #eeeaf4; */
    /* Border radius for the rounded edges */
    border: 1px solid #ccc;
    /* Border style */
    font-size: 16px;
}

.value-display {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #7a6eac;
    /* Lighter color for the value */
    font-size: 16px;
}

.token-input {
    border: none;
    background-color: #eeeaf4;
    font-size: 18px;
    width: 100%;
    text-align: right;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, .1);
}

.swap-arrow {
    margin: 10px 0;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 0px inset; */
}

.swap-arrow i {
    color: #7c7c7c;
    font-size: 16px;
    background-color: white;
    border: 1px solid #e7e7e7;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 0px inset;
    border-radius: 38px;
    padding: 10px 13px;
}

.slippage-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #7645d9;
}

.slippage-section .input {
    border: none;
    background: transparent;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    color: #3e8fcb;
}

.connect-wallet-btn {
    width: 100%;
    padding: 0px 24px;
    height: 48px;
    background-color: #36c5f0;
    color: white;
    font-weight: 600;
    border: none;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
}

.connect-wallet-btn:hover {
    background-color: #1f9bd1;
}

footer {
    background-color: #21212b;
    color: #fff;
    padding: 20px 0;
    font-family: Arial, sans-serif;
}

/* .footer-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    flex-wrap: wrap;
} */

.footer-column {
    margin-bottom: 40px;
}

.footer-column h3 {
    color: #a881fc;
    margin-bottom: 10px;
    font-size: 16px;
}

.footer-column ul {
    list-style: none;
    padding: 0;

}

.footer-column ul li {
    margin-bottom: 8px;
}

.footer-column ul li a {
    color: #f4eeff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
}

.footer-column ul li a:hover {
    color: #9c6ade;
}

.footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid #333;
    margin-top: 20px;
}

.social-icons a {
    color: #fff;
    font-size: 18px;
    margin: 0 10px;
    text-decoration: none;
}

.settings button,
.cake-price button {
    background: #23d3ea;
    border: none;
    color: #000;
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.settings {
    display: flex;
    align-items: center;
}

.cake-price {
    display: flex;
    align-items: center;
}

.cake-price span {
    margin-right: 10px;
}

.swap-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    margin: 23px 5px;
    background: var(--primary-background-color);
    /* background: linear-gradient(180deg, #e0f7ff 0%, #f8fcff 100%); */
}

.Newswap-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    margin: 23px 5px;
    background: var(--primary-background-color);
    /* background: linear-gradient(180deg, #e0f7ff 0%, #f8fcff 100%); */
}

.swap-header {
    text-align: center;
}

/* .icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.icons i {
    font-size: 1.2rem;
    color: #777;
    cursor: pointer;
} */

.icons {
    display: flex;
    justify-content: flex-end !important;
    /* Align icons to the left */
    gap: 15px;
    margin-top: 10px;
}

.icons i {
    font-size: 1.3rem;
    color: #e1e1e1;
    cursor: pointer;
}

.swap-body {
    margin-top: 20px;
}

.swap-input img {
    width: 25px;
    height: 25px;
}


.swap-arrow {
    text-align: center;
}

.swap-arrow i {
    font-size: 1.5rem;
    color: #777;
}

.slippage {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 0.9rem;
    color: #333;
}

/* .percentage {
    color: #38c172;
} */

/* .connect-wallet-btn {
    width: 100%;
    padding: 12px;
    background-color: #38c172;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
}

.connect-wallet-btn:hover {
    background-color: #2f9e60;
} */

.swap-card {
    background-color: var(--primary-section-background-color);
    border-radius: 20px;
    padding: 25px;
    box-shadow: 0 6px 15px rgb(0 0 0 / 39%);
    width: 350px;
}

.newSwap-card {
    /* background-color: var(--primary-section-background-color); */
    background-image: url('../img/card_background_1.png');
    background-size: cover;
    /* Ensures the image covers the element */
    background-repeat: no-repeat;
    /* Prevents the image from repeating */
    background-position: center;
    border-radius: 20px;
    padding: 25px;
    box-shadow: 0 6px 15px rgb(0 0 0 / 39%);
    width: 350px;
}

.newSwap_para_1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
}

.newSwap_para_2 {
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 0;
}

.sab_nav_link_div {
    background-color: var(--primary-section-background-color);
    margin: 47px 0 0 0;
}

.sab_nav_link {
    margin: 0px;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: rgba(133, 133, 133, 0.1) 0px -2px 0px -8px inset;
    /* // background: 'linear-gradient(170deg, #494949, #00bfff69)', */
    /* // overflowX: 'scroll',  // Enable horizontal scrolling */
    white-space: nowrap;
    list-style-type: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.swap-header {
    text-align: left;
    margin-bottom: 20px;
}

.swap-header h2 {
    font-size: 1.6rem;
    color: var(--primary-text-color);
    margin-bottom: 5px;
}

/* .icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}

.icons i {
    font-size: 1.3rem;
    color: #888;
    cursor: pointer;
} */

.swap-body {
    margin-top: 20px;
}

.swap-input {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-background-color);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid var(--seconday-border-color);
}

.swap-input2 {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-background-color);
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 15px;
    border: 1px solid var(--seconday-border-color);
}

.swap-input2 img.token-logo {
    width: 30px;
    height: 30px;
}

.swap-input img.token-logo {
    width: 30px;
    height: 30px;
}

.swap-input input {
    border: none;
    /* background: transparent; */
    width: 100%;
    text-align: right;
    font-size: 1.2rem;
    color: var(--primary-text-color);
}

.swap-input2 input {
    border: none;
    /* background: transparent; */
    width: 100%;
    text-align: right;
    font-size: 1.2rem;
    color: var(--primary-text-color);
}

.swap-input input:hover {
    border: none;
    /* background: transparent; */
    width: 100%;
    text-align: right;
    font-size: 1.2rem;
    color: var(--primary-text-color);
}

.swap-input2 input:hover {
    border: none;
    /* background: transparent; */
    width: 100%;
    text-align: right;
    font-size: 1.2rem;
    color: var(--primary-text-color);
}

.placeholder-white::placeholder {
    color: var(--primary-text-color);
}

.swap-arrow {
    text-align: center;
    margin: 10px 0;
}

.swap-arrow i {
    font-size: 1.5rem;
    color: #1b1b1b;
}

.slippage {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 0.9rem;

    color: #280d5f;
}

.percentage {
    color: #1fc7d4;
    font-weight: 600;
    font-size: 16px;
}

.connect-wallet-btn {
    width: 100%;
    padding: 15px;
    background-color: #1fc7d4;
    color: white;
    border: none;
    border-radius: 15px;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 20px;
}

.connect-wallet-btn:hover {
    background-color: #2f9e60;
}

/* .footer-section {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.6), rgba(245, 247, 251, 0.6));
    padding: 20px 0;
} */

.footer-link {
    color: #b08e60;
    text-decoration: none;
    font-weight: bold;
}

.footer-link i {
    margin-left: 5px;
}

.help-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* background-color: #1b1b1b; */
    background-color: var(--primary-section-background-color);
    /* background: linear-gradient(135deg, #ffffff, #1fc7d48c, #ffffff); */
    border: 1px solid #b6b6b6;
    padding: 15px 10px;
    border-radius: 15px;
    margin-top: 11px;
    margin-bottom: 50px;
    /* box-shadow: 1px 1px 2px #aeaeae; */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.411);

}

#need-help-btns {
    background-color: #b08e60;
    color: black;
    font-weight: bold;
    border-radius: 20px;
    padding: 5px 15px;
    margin-right: 10px;
}

.bunny-img {
    height: 120px;
    width: auto;
}

.percentage-selection {
    color: var(--primary-text-color);
    font-weight: bolder;
    border: 2px solid var(--primary-black-border-color);
    padding: 0 8px;
    border-radius: 25px;
}

.portfolio-card {
    background: linear-gradient(145deg, var(--primary-gradient-start), var(--primary-gradient-start));
    border-radius: 12px;
    box-shadow: 0 8px 16px var(--shadow-color);
    color: var(--text-color);
    padding: 20px;
    margin-bottom: 20px;
    max-width: 100%;
    margin: 0 10px;
    transition: transform 0.3s ease;
}

.portfolio-card:hover {
    transform: scale(1.02);
}

.portfolio-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.portfolio-title {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
}

.token-badge {
    background-color: var(--white-translucent);
    padding: 5px;
    border-radius: 8px;
}

.token-item {
    display: flex;
    align-items: center;
    text-align: center;
}

.token-logo {
    width: 30px;
    margin-right: 8px;
}

.token-symbol {
    margin: 0;
    font-weight: bold;
}

.portfolio-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.portfolio-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detail-label {
    font-size: 1rem;
    margin: 0;
    /* opacity: 0.8; */
    color: #fff;
}

.detail-value {
    font-size: 1.1rem;
    text-align: right;
    color: #fff;
}

@media (max-width: 480px) {
    .portfolio-card {
        margin: 0 20px;
        padding: 15px;
    }

    .portfolio-title {
        font-size: 1rem;
    }

    .token-logo {
        width: 25px;
    }

    .phi_token_logo {
        width: 25px;
        height: 25px;
    }

    .detail-label,
    .detail-value {
        font-size: 0.9rem;
    }
}

:root {
    --primary-gradient-start: #b69f66;
    --primary-gradient-end: #7a5d31;
    --white-translucent: rgba(255, 255, 255, 0.2);
    --text-color: white;
    --shadow-color: rgba(0, 0, 0, 0.2);
}

.Icon_container {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.icon_box {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
}

.buy_image {
    width: 120px;
    border-radius: 25px;
}

.buy_button {
    background-color: #b69f66;
    padding: 5px 30px;
    border-radius: 25px;
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

.sell_button {
    background-color: #f0f0f0;
    padding: 5px 30px;
    border-radius: 25px;
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

.phi_token_logo {
    width: 25px;
    height: 25px;
}