:root {
    --primary-background-color: #fff;
    --primary-tab-background-color: #ededed;
    --primary-border-color: #fff;
    --seconday-border-color: #ededed;
    --primary-section-background-color: #e1e1e1;
    --secondary-section-background-color: #f3f3f3;
    --primary-text-color: #000;
    --secondary-text-color: #141414;
    --tertiary-text-color: #1b1b1b;
    --primary-black-border-color: #000;

}


marquee {
    margin-top: 4px;
}

.dashboard_marque {
    margin-top: 15px;
    background: black;
    background: linear-gradient(229deg, #a2895e -13.69%, #453e35 91.33%);
    color: white;
}

.dashboard_row {
    margin: 0;
    padding: 0px 8px;
}

.dashboard_col_4 {
    padding: 0px 8px;
}

.income-container {
    margin: 10px 2px;
}

.income-card {
    background-color: #e8f6ff;
    width: 150px;
    padding: 20px;
    border-radius: 10px;
    margin: 10px 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    border: 1px solid #009dff;
}

.income-card p {
    margin-bottom: 0px;
}

.income-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.icon {
    font-size: 30px;
    margin-bottom: 10px;
}

.income-card h3 {
    font-size: 24px;
    margin: 10px 0;
    color: #000;
}

.income-card p {
    font-size: 16px;
    color: #666;
}

@media (max-width: 576px) {
    .income-card {
        width: 105px;
        padding: 15px 10px;
    }

    .icon {
        font-size: 20px;
    }

    .income-card h3 {
        font-size: 18px;
    }

    .income-card p {
        font-size: 14px;
    }
}


.portfolio-container {
    background-color: #e9f2fb;
    /* background-color: #fcf6ec; */
    padding: 20px;
    border-radius: 10px;
}

.portfolio-income-card:hover {
    background-color: var(--primary-section-background-color);
}

.portfolio-available-coin {
    font-weight: bold;
    background-color: #fff;
    padding: 5px 15px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px #a3a3a3;
}

.portfolio-income-card {
    background-color: var(--primary-section-background-color);
    border: 1px solid var(--secondary-section-background-color);
    border-radius: 10px;
    text-align: center;
    padding: 15px;
}

.new_portfolio-income-card {
    background-color: #b69f66 !important;
    background-color: var(--primary-section-background-color);
    border: 1px solid var(--secondary-section-background-color);
    border-radius: 10px;
    text-align: center;
    padding: 15px;
}

.portfolio-income-card h5 {
    margin: 10px 0;
    font-size: 16px;
}

.portfolio-income-card p {
    font-size: 18px;
    font-weight: 400;
}

.portfolio-icon {
    width: 50px;
    height: 50px;
    /* margin-bottom: 10px; */
}

.portfolio-income-card-other {
    /* padding: 9px 29px; */
    display: flex;
    justify-content: space-around;
    text-align: left;
    align-items: center;
}

/* Responsive styles */
@media (max-width: 768px) {
    .portfolio-income-card {
        padding: 10px 5px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .portfolio-income-card-other {
        /* padding: 9px 29px; */
        display: flex;
        justify-content: space-around;
        text-align: left;
        align-items: center;
    }

    .portfolio-income-card h5 {
        font-size: 11px;
    }

    .dashboard_heading {
        font-size: 12px;
        margin: "10px 0";
        font-weight: 600;
        color: var(--primary-text-color);
    }

    .portfolio-income-card p {
        font-size: 14px;
        font-weight: 400;
    }

    .portfolio-icon {
        width: 40px;
        height: 40px;
    }

    .portfolio-icon-other {
        width: 62px;
        height: 62px;
    }
}

.terms-container {
    max-width: 850px;
    padding: 25px;
    margin: auto;
    /* background-color: #f9f9f9; */
    color: #333;
    font-family: 'Helvetica', sans-serif;
}

.terms-header-section {
    text-align: center;
    margin-bottom: 30px;
}

.terms-title {
    font-size: 2.7rem;
    color: #343a40;
}

.terms-update {
    font-size: 1.1rem;
    color: #6c757d;
}

.terms-body-section {
    padding: 20px;
}

.terms-section {
    margin-bottom: 40px;
}

.terms-subtitle {
    font-size: 1.9rem;
    color: #0056b3;
    margin-bottom: 12px;
}

.terms-text {
    font-size: 1.1rem;
    line-height: 1.7;
    color: #4a4a4a;
}