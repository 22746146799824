.CommingSoonBody {
    background: white;
    /* height: 100vh; */
}

.coming-soon-container {
    position: relative;
    width: 400;
    /* width: 50vw;
    height: 50vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.coming-soon-image {
    /* width: 100%;
    height: 100%; */
    margin-top: 80px;
    object-fit: cover;
}

.coming-soon-text {
    /* position: absolute; */
    color: #b08e60;
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 2px 2px 1px rgba(255, 249, 193, 0.7);
    text-align: center;
}

@media (max-width: 576px) {
    .coming-soon-text {
        font-size: 2rem;
    }
}