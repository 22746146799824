.total_business {
    background-color: #a27e4e;
    margin: 10px 16px;
    padding: 10px;
    border-radius: 15px;
    align-content: center;
    display: flex;
    justify-content: center;

}

.total_business_team {
    background-color: #a27e4e;
    margin: 10px 16px;
    padding: 10px;
    border-radius: 15px;
    align-content: center;
    /* display: flex;
    justify-content: center; */

}

.level_heading_stakes {
    margin: 19px;
    background: linear-gradient(229deg, rgb(31, 199, 212) -13.69%, rgb(118, 69, 217) 91.33%);
    font-weight: 700;
    margin: 19px 19px 10px 19px;
    padding: 9px;
    border: none;
    border-radius: 11px;
    color: white;
}

.user_address {
    background-color: #a27e4e;
    padding: 15px 10px;
    color: black;
    text-align: center;
    margin: 10px 16px;
    border-radius: 15px;
    font-size: 18px;
    word-wrap: break-word;
    /* Ensures long text wraps within the container */
    word-break: break-all;
    /* Allows breaking words if they are too long */
}